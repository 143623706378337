.catalog_section{padding:0 0 50px 0;top:-100px}
.catalog_slider h2{
    color: #232323;
    text-align: center;
    font-family: 'SF Pro Display Semibold' !important;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px; /* 175% */
    text-transform: capitalize;
}
.catalog_slider .mob_img{display: none;}
.catalog_slider ul img{width: 60%;}
.catalog_slider ul .asos img{position: relative;top:25px;left: 30px;}
.catalog_slider ul .zara img{position: relative;top:25px;}
.catalog_slider ul .mango img{position: relative;left:25px;top:20px;}
.catalog_slider ul .lululemon img{position: relative;top:25px}
.catalog_slider ul .adidas img{position: relative;top:10px;}
.catalog_slider ul .nike img{position: relative;top:30px;}
.catalog_slider ul .lacoste img{position: relative;top:15px;}
.catalog_slider ul .burberry img{position: relative;top:25px;}

/*.catalog_slider ul img{width: 60%;object-fit: cover;}*/

/*@media only screen and (-webkit-min-device-pixel-ratio: 2) {*/
/*    .catalog_slider img {*/
/*       width: 60%;*/
/*        object-fit: cover;*/
/*        min-height: 0; !* Set a value that suits your design *!*/
/*        min-width: 0;  !* Set a value that suits your design *!*/
/*    }*/
/*    .catalog_slider ul .lululemon img{position: relative;top:20px;}*/
/*    .catalog_slider ul .burberry img{position: relative;top:15px;}*/
/*    .catalog_slider ul .mango img{position: relative;top:15px;left:0;}*/
/*}*/
/* Media query for screens with a width less than 768px */
@media (max-width: 768px) {
    .catalog_section {
        padding:0;
        /*background: #fff;*/
        top:-50px;

    }
    .catalog_slider .mob_img{display: block;}
    .catalog_slider .web_img{display: none;}

    .catalog_slider ul img{width: 100%;object-fit: cover;
    }
    .catalog_slider h2{
    font-size: 16px;
}
    .catalog_slider ul .asos img{position: relative;top:20px;left: 0;}
    .catalog_slider ul .zara img{position: relative;top:25px;}
    .catalog_slider ul .mango img{position: relative;left:0;top:20px;}
    .catalog_slider ul .lululemon img{position: relative;top:25px}
    .catalog_slider ul .adidas img{position: relative;top:0;}
    .catalog_slider ul .nike img{position: relative;top:20px;}
    .catalog_slider ul .lacoste img{position: relative;top:10px;}
    .catalog_slider ul .burberry img{position: relative;top:25px;}
}
@media(max-width: 400px){

    .catalog_slider ul .burberry img{position: relative;top:25px;width: 80%;}
    .catalog_slider ul .lacoste img{position: relative;top:15px;}
    .catalog_slider ul .zara img{position: relative;top:35px;}
    .catalog_slider ul .asos img{position: relative;top:25px;left: 0;}



}
@media (min-width: 400px) and (max-width: 600px){

    .catalog_slider ul .burberry img{position: relative;top:25px;width: 80%;}
    .catalog_slider ul .zara img{position: relative;top:35px;}
    .catalog_slider ul .lacoste img{position: relative;top:15px;}
    .catalog_slider ul .asos img{position: relative;top:25px;left: 0;}




}
@media (max-width: 992px) {

    .catalog_slider ul img{width: 80%;}
    /*.catalog_slider ul .lululemon img{position: relative;top:20px;}*/
    /*.catalog_slider ul .burberry img{position: relative;top:15px;}*/
    /*.catalog_slider ul .mango img{position: relative;top:15px;left:0;}*/


}