.feeds_section{padding: 50px 0;}
.feeds_container{
    width: 90% !important;
}
.feeds_listing h1{
    display: flex;
    flex-shrink: 0;
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 67px; /* 223.333% */
}
.filter_cats ul{padding-left: 0;}
.filter_cats ul li{
    display: inline-flex;
    padding: 4px 30px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 40px;
    background: #F5F5F5;
    color: #2A2A2A;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 160% */
    margin-right:5px;
    margin-bottom: 5px;
    cursor:pointer;
}
.filter_cats ul li:hover{
    display: inline-flex;
    padding: 4px 30px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 40px;
    background: #2A2A2A;;
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 160% */
    margin-right:5px;
    cursor:pointer;
}
.filter_cats p{
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px; /* 150% */
    opacity: 0.4;
    width: 528px;
}
.filter_cats .pagination{float:right;}
.filter_cats .pagination img{display: inline-flex;margin-right: 15px; cursor: pointer;}
.product_bg{
    margin-top: 35px;
    border-radius: 20px;
    background: #F5F5F5;
    height: auto;
    flex-shrink: 0;
}
.product_img img{margin: 0 auto;}
.dot_img{
    position: absolute;
    bottom: 30%;
    right: 0;
}
.like_img{
    position: absolute;
    bottom: 23%;
    right: 0;
}
.product_selection .product_bg .action_close_btn{
    position: absolute;
    top: 20%;
}
.product_selection .product_bg .action_like_btn{
    position: absolute;
    top: 20%;
    right: 0;
}
.product_description{background: #000;padding: 10px 15px;}
.product_description .product_cat_info p{
    margin-bottom: 0;
   }
.product_description .product_cat_info .product_tag{
    border-radius: 12px;
    background: #70CAD1;
    font-family: Livvic;
    font-size: 13px;
    font-weight: 500;
    color:#fff;
    line-height: 14px;
    letter-spacing: 0.15000000596046448px;
    padding: 4px 8px;
    color: #2A2A2A;
    -webkit-text-stroke: 0.6px;}
.product_description .product_cat_info .product_review{
    color: #FFF;
    font-family: Livvic;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 145.455% */
    letter-spacing: 0.5px;
    margin-right: 5px;
}
.product_description .product_cat_info .remaining{
    color: #FFF;
    font-family: Livvic;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 114.286% */
    letter-spacing: 0.5px;
    float: right;
}
.product_description .product_cat_info  .remaining img{
    display: inline-flex;
}
.product_description .product_cat_info  .product_title{
    color: #FFF;
    font-family: Livvic;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 120% */
    letter-spacing: 0.15px;
}
.product_description .product_cat_info  a{
    text-decoration: none !important;
}
.product_description .product_cat_info  .product_price{
    color: #FFF;
    text-align: right;
    font-family: Livvic;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 96% */
    letter-spacing: 0.15px;
    float: right;
}
/* Media query for screens with a width less than 768px */
@media (max-width: 768px) {
    .filter_cats p { width:100%  !important;}
}
@media screen and (min-width: 1440px) {
    .feeds_container {
        max-width: 1440px !important;
    }
}