
.virtual-section {padding: 50px 0;}
.virtual-container{width:90% !important;}
.grey-box{
    width: 100%;
    height: 85.6639px;
    top: 1290px;
    left: 81px;
    border-radius: 13px;
    border: none;
    background: rgb(245, 245, 245);
    margin: 10px 0px;
}
.active-box{
    width: 100%;
    height: 85.6639px;
    top: 1290px;
    left: 81px;
    border-radius: 13px;
    border: none;
    background: rgb(112, 202, 209);
    margin: 10px 0px;
}
.heading-virtual {
    font-family: Livvic;
    font-size: 50px;
    font-weight: 700;
    line-height: 73px;
    letter-spacing: 0em;
    text-align: left;
    -webkit-text-stroke: 3px;
    margin-left: 2%;
    margin-top: -5px;
}

.virtualText-p {
    font-family: Livvic;
    font-size: 20px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    -webkit-text-stroke: 0.5px;
    margin-top: 1px;
}

.girl-section {
    flex: 0.7;
    padding-left: 10%;

}

.background-cropper {
    height: 100%;
    width: 100%;
    position: relative;
    background-image: url("../../../assets/images/Rectangle\ 16.png");

}

@media (max-width: 768px) {


    .virtualText-p {
        /*width: 50%;*/
        font-family: "Livvic";
        font-size: 12px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        /*margin-left: 40%;*/
        /*margin-top: -50px;*/

    }

    .heading-virtual {
        font-family: 'Livvic';
        font-size: 20px;
        font-weight: 700;
        line-height: 50px;
        letter-spacing: 2px;
        /*text-align: left;*/
        /*margin-left:40%;*/

    }

    .girl-section {
        flex: 0.9;
    }
}
@media screen and (min-width: 1440px) {
    .virtual-container {
        max-width: 1440px !important;
    }
}