.features_section{margin:130px 0;padding: 50px 0;}
.features_bg{
    background: url("../../../assets/images/features-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 30px;
    padding: 35px 0 30px 0;
}

.features_bg h1{
    color: #232323;
    text-align: center;
    font-family: 'Helvetica bold' !important;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}
.feature_list{padding: 0 90px;}
.feature_info{
    border-radius: 26.744px;
    background: #FFF;
    padding: 0px 33.875px 26.744px 33.875px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8.915px;
}
.feature_info h2{
    color: #52BFC1;
    text-align: center;
    font-family: 'SF Pro Display Semibold' !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 33.875px; /* 169.377% */
    text-transform: capitalize;
}
.feature_info p span{
    color: #666;
    text-align: center;
    font-family: 'SF Pro Display Semibold' !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24.961px; /* 138.672% */
}
.feature_info p {
    color: #666;
    font-family: "SF Pro Display";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.961px;
    text-align: center;
}
.feature_info img{
    margin:0 auto;
    margin-bottom: 25px;
}
/* Media query for screens with a width less than 768px */
@media (max-width: 768px) {
    .features_section{background: #fff; margin:0;}
    .features_bg{
        background: url("../../../assets/images/features-mob.svg");
        background-repeat: no-repeat;
        background-size: cover;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        padding: 35px 0 30px 0;
    }
    .feature_list{padding: 0 30px;}
    .feature_info{margin-bottom: 20px;}
    .features_bg h1{
        color: #232323;
        text-align: center;
        font-family: 'Helvetica bold' !important;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 140% */
        text-transform: capitalize;
    }
    .feature_info h2{
        color: #52BFC1;
        text-align: center;
        font-family: 'SF Pro Display Semibold' !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
        text-transform: capitalize;
    }
    .feature_info p span{
        color: #666;
        text-align: center;
        font-family: 'SF Pro Display Semibold' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }
    .feature_info p {
        color: #666;
        font-family: "SF Pro Display";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
}
@media (min-width: 768px)and (max-width: 1200px){
    .feature_info{margin-bottom: 20px;}
    .features_bg{
        background: url("../../../assets/images/features-mob.png");
        background-repeat: no-repeat;
        background-size: cover;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        padding: 35px 0 30px 0;
    }
}
@media (min-width: 1201px)and (max-width: 1399px){
    .feature_info{margin-bottom: 20px;}
    .features_container{max-width: 1200px !important;}
    .features_bg{
        background: url("../../../assets/images/features-bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
        border-top-right-radius: 50px;
        padding: 35px 0 30px 0;
    }
}
@media (min-width: 1300px){

    .features_container{max-width: 1200px !important;}

}