.banner_section{padding: 0 0 50px 0;}
.banner_img img{width: 90%;}
.banner_text{margin-top: 150px;}
.visually-hidden {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    margin: -1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    border: 0 !important;
}

.banner_text h1{
    color: #232323;
    font-family: 'SF Pro Display Semibold' !important;
    font-size: 65px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.banner_text h1 span img{
    position: absolute;
    right: 80px;
    bottom: -25px;
}
.banner_text p{
    margin-top: 50px;
    color: #666;
    font-family: "SF Pro Display" !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 140% */
    max-width: 522px;
}
.banner_text p span{
    margin-top: 50px;
    color: #666;
    font-family: 'SF Pro Display Semibold' !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 140% */
}
.mob_download_btn{
    color: #FFF;
    font-family: 'Helvetica bold' !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 19.531px;
    background: #52BFC1;
    display: none;
    text-decoration: none;
    padding: 9.114px 18.88px;
    width: 50%;
    margin: 0 auto;
    text-align: center;
}
/*.banner_img img{width:100%;height: auto;}*/
.banner_text ul{display: inline-flex;}
.banner_text ul li{margin-right: 20px; width: 100%;}
.banner_text ul li:last-child{margin-right:0;}
.desktop_hide{display: none;}
/* Media query for screens with a width less than 768px */
@media (max-width: 768px) {
    .banner_section{padding:0;}
    .banner_img img{width: 90%;margin: 0 auto;margin-top:70px;}
    .mob_banner_bg{
        background: url("../../../assets/images/banner-mob-bg.png");
        background-repeat: no-repeat;
        background-size: cover;
    }
    .mob_hide{display: none;}
    .desktop_hide{display: block;}
    .banner_text{margin-top: 0;}
    .banner_text h1{
        color: #232323;
        font-family: 'SF Pro Display Semibold' !important;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
    }
    .banner_text h1 span img{
        width: 40%;
        position: absolute;
        right: 50px;
        bottom: -5px;
    }

    .banner_text p{
        margin-top: 20px;
        color: #666;
        font-family: "SF Pro Display" !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 140% */
        max-width:max-content;
        text-align: center;
    }
    .banner_text p span{
        margin-top: 50px;
        color: #666;
        font-family: 'SF Pro Display Semibold' !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 140% */
    }
    .banner_text ul{display: none;}
    .mob_download_btn{display: block;}
}
@media (min-width: 993px) and (max-width: 1200px){
    .banner_text{margin-top: 0;}

}