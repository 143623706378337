.banner-section {
    padding: 50px 0;
    background: #000;
    height: 92vh;
}

.banner-section .carousel-item img {
    /*max-height: 800px;*/
    width: 100%;
    display: block;
    margin: 0 auto; /* Center the images horizontally */
}

.banner-section .carousel-indicators {
    display: none !important;
}

.banner-section .banner-carousel {
    padding-top: 70px;
    width: 100%;
    overflow: hidden;
}
.app-links{text-align:center;color: #fff;padding: 70px 50px;}
.app-link-title h1{font-size: 48px;}
.app-link-title p{font-size: 18px;}
.app-links .apple-image-div {
    /*width: 100px;*/
    height: 52px;
    border-radius: 10px;
    border: 0.5px solid lightgray;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #fff;

}

.app-links .apple-image-div img {
    height: 80%;
    width: 80%;
}


.app-links .playstore-image-div {
    /*width: 100px;*/
    height: 52px;
    border-radius: 10px;
    border: 0.5px solid lightgray;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #fff;
}

.app-links .playstore-image-div img {
    height: 80%;
    width: 80%;
}
.app-links .get-started-btn{
    background: #fff !important;
    color: #000;
    border-radius: 10px;
    margin: auto;
    width: 120px;
    font-size: 14px;
    height: 40px;
}
.app-links .playstore-image-div img {
    height: 80%;
    width: 100%;
}
.app-links .apple-image-div img{
    height: 80%;
    width: 100%;
}
@media (max-width: 768px) {
    .banner-section {
        height: auto;
    }
    .app-links .playstore-image-div, .app-links .apple-image-div{width: 100%;}
}
/*.banner-section .carousel-image {*/
/*    width: 90%; !* Ensure the image takes up 100% of the container width *!*/
/*    height: 100%; !* Ensure the image takes up 100% of the container height *!*/
/*    object-fit: cover; !* Adjust as needed: cover, contain, fill, etc. *!*/
/*}*/