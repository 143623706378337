/*.mobile-logo{*/
/*    display: none;*/
/*}*/
/*@media (max-width: 768px) {*/
/*    .mobile-logo{*/
/*        display: block;*/
/*    }*/
/*}*/

/* Show the mobile NavbarBrand on screens smaller than md */
/* @media (max-width: 768px) {
    .navbar-brand-mobile {
        display: block;
    }
} */
.header-section{background:#000;}
.header-section .navbar-brand{color: #fff !important;text-decoration: none !important;font-size: 25px !important;}
.cstm-nav .nav-item .nav-link{color:#fff !important;}
.cstm-nav .navbar-nav .nav-item .notify-img{width: 100% !important;}
.cstm-nav .navbar-nav .nav-item img{width: 30%;display: inline-flex;padding-right: 5px;}
.mobile-logo{display: none}
/*.cstm-nav{padding-top: 30px;}*/
@media (max-width: 768px) {
    .mobile-logo{
        display: flex;
    }
    .header-section .navbar-toggler{
        background: #e9ecef !important;
    }
}
@media screen and (min-width: 1440px) {
    .cstm-container {
        max-width: 1440px !important;
    }
}