.container-rc {
    /*height: 90vh;*/
    display: flex;
    flex-direction: column;
    padding: 50px 0;
    background:#000;
}
.bg-dark-grey{background: #2a2a2a;padding: 50px 0;}
/*.wrapper {*/
/*    display: flex;*/
/*    width: 100%;*/
/*    height: 100vh;*/
/*}*/

.black-background {
    /* background-color: black; */
    /*width: 50%;*/
    /*padding-left: 5%;*/
    /*height: 90%;*/
}

.get-started-btn {
    width: 250px;
    height: 50px;
    top: 721px;
    left: 107px;
    border-radius: 40px;
}

.btn-arrow {
    width: 40px;
    height: 40px;
    top: 727px;
    left: 323px;
}

.back-image {
    /*height: 80vh;*/
    /*width: 85%;*/
    background-image: url('../../../assets/images/Subtract.png');
    background-size: 100% 100%;
    margin-top: 35px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.style-div, .gender-selection{
    /*height:80vh;*/
    height:600px;
    width: 60%;
    margin-left: 10%;
}
.style-div-child {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 85%;
    justify-content: space-evenly;
    margin: 10% 5%;
    width: 100%;
}
.style-div-header {
    align-items: center;
    display: flex;
    height: 57px;
    justify-content: center;
    width: 354px;
}
.style-div-header h2 {
    -webkit-text-stroke: 3px;
    color: #fff;
    font-family: Livvic;
    font-size: 56px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 28px;
    text-align: left;
}
.alignment-div img {
    height: 100%;
    width: 100%;
}
.let-us-knew {
    height: 120px;
    width: 403px;
}
.let-us-knew p {
    color: #fff;
    font-family: Livvic, sans-serif;
    font-family: Londrina Outline, sans-serif;
    font-size: 45px;
    font-weight: 900;
    letter-spacing: .11em;
    line-height: 47px;
    margin-left: 15%;
    text-align: left;
}
.swipe-right {
    height: 26px;
    margin-left: 10%;
    width: 309px;
}

.swipe-right p {
    color: #fff;
    font-family: Livvic;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 26px;
    text-align: left;
}

.get-started-btn {
    align-items: center;
    background: #70cad1;
    border: none;
    border-radius: 40px;
    display: flex;
    height: 50px;
    justify-content: space-around;
    /*width: 270px;*/
}
.btn-arrow {
    background-color: #fff;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADaSURBVHgB7ZXPDYIwGEd/VAZgE+3BqPHiCI7gBtYJhAnsSF6MEQ+FcUyUWkNiwD+0X8uRl5BA+HgvkNACAxairptCqQR3TEBATvmxeR13Tt+wAWMHEBAnxeWSF04BOedSnFUBG2y0RaTX9TmS5q3Y9qxctF/5E3Et99AveWTm9OqrjQBqOVJzZIge2a8Z70BTLmfj9N+cV8BV7hWgyMkBqpwU8JE7B3zlToEQuTUQKu8MkOUxClTVzvbnv+UiL7W4lCn6pm95az8QuTJrP1Mh39yKWc9JG8xAME8/SX1OGTJTawAAAABJRU5ErkJggg==);
    background-size: 90% 100%;
    border-radius: 50%;
    height: 35px;
    margin-left: 40%;
    width: 35px;
}
.get-started-btn p {
    color: #fff;
    margin-left: 5%;
    margin-top: 5%;
}
.gender-selection h1{
    color: #FFF;
    font-family: Livvic;
    font-size: 39px;
    font-style: normal;
    font-weight: 500;
    line-height: 73px; /* 187.179% */
}
.gender-selection p{
    color: #FFF;
    font-family: Livvic;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 144% */
}
.btn-option{
    position: relative;
    border-radius: 20px;
    background: #FFF;
    width: 100%;
    height: 50px;
    flex-shrink: 0;
    margin-bottom: 10px;
    cursor: pointer;
}
.btn-option p{
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px; /* 144% */
    margin-bottom: 0 !important;
}
.btn-option img{
    display: inline-flex;
    width:40px;
    height:40px;
    padding-left: 20px;
}
.btn-option .grey-bubble {
    width: 26px;
    height: 26px;
    flex-shrink: 0;
    background: #D9D9D9;
    opacity: 0.5;
    float: right;
    margin-right: 20px;
    margin-top: 10px;
    border-radius: 50px;
}
.loader-img{
    position: absolute;
    z-index: 1;
    left: 0;
    right: 15%;
    top: 40%;
}
.cstm-loader{
    color: #70CAD1 !important;
}
.quiz-info p{
    color: #2A2A2A;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Livvic;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    width: 70%;
}
.category-list ul{
    padding: 0;
}
.category-list ul li{
    border-radius: 10px;
    border: 1px solid #2A2A2A;
    background: #FFF;
    display: inline-flex;
    color: #2A2A2A;
    font-family: Livvic;
    padding: 5px 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.5px;
    margin: 5px;
    cursor:pointer;
}
.category-list ul li:hover{
    border-radius: 10px;
    border: 1px solid #70CAD1;
    background: #FFF;
    color: #70CAD1;
    font-family: Livvic;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.5px;
}
.category-list ul .active{
    border-radius: 10px;
    border: 1px solid #70CAD1;
    background: #FFF;
    color: #70CAD1;
    font-family: Livvic;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.5px;
}
.btn-option .green-bubble {
    width: 26px;
    height: 26px;
    flex-shrink: 0;
    background: #70cad1;
    opacity: 0.5;
    float: right;
    margin-right: 20px;
    margin-top: 10px;
    border-radius: 50px;
}
.next-cat-btn{
    align-items: center;
    background: #70cad1;
    border: none;
    border-radius: 40px;
    display: flex;
    height: 50px;
    justify-content: space-around;
    color: #fff;
    padding: 20px;
}
.product-selection .product-bg{
    margin-top: 35px;
    border-radius: 20px;
    background: #F5F5F5;
    width:80%;
    height: auto;
    flex-shrink: 0;
    border:1px solid #e1e1e1;
}
.product-img img{margin: 0 auto;max-height: 450px;width:auto;min-height: 450px;}
.product-selection .product-bg .action-close-btn{
    position: absolute;
    top: 20%;
}
.product-selection .product-bg .action-like-btn{
    position: absolute;
    top: 20%;
    right: 0;
}
.product-description{background: #fff;padding: 20px;border-radius: 20px;}
.product-description .product-cat-info .product-tag{
    border-radius: 12px;
    background: #70CAD1;
    font-family: Livvic;
    font-size: 13px;
    font-weight: 500;
    /*color:#fff;*/
    line-height: 14px;
    letter-spacing: 0.15000000596046448px;
    padding: 4px 8px;
    color: #2A2A2A;
    -webkit-text-stroke: 0.6px;}
.product-description .product-cat-info .product-review{
    /*color: #FFF;*/
    font-family: Livvic;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 145.455% */
    letter-spacing: 0.5px;
}
.product-description .product-cat-info .remaining{
     /*color: #FFF;*/
     font-family: Livvic;
     font-size: 14px;
     font-style: normal;
     font-weight: 500;
     line-height: 16px; /* 114.286% */
     letter-spacing: 0.5px;
    float: right;
}
.product-description .product-cat-info .remaining img{
    display: inline-flex;
}
.product-description .product-cat-info .product-title{
    /*color: #FFF;*/
    font-family: Livvic;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 92.308% */
    letter-spacing: 0.15px;
}
.product-description .product-cat-info .product-price{
    /*color: #FFF;*/
    text-align: right;
    font-family: Livvic;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 80% */
    letter-spacing: 0.15px;
    float: right;
}
/*.boxes {*/
/*    width: 50%;*/
/*}*/

.box {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px;
    text-align: center;
}
.modal-action-btns{display: flex;width: 100%;}
.cstm-modal-btn{
    margin: 0 5px;
    width: 48%;
    padding: 5px 10px !important;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
    color: #FFF !important;
    font-family: Livvic;
    font-size: 20px !important;
    border-radius: 7px !important;
    background: #70CAD1 !important;
    border:1px solid #70CAD1 !important;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
    letter-spacing: 0.5px;
}
.recomendation-heading {
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.recomendation-heading h1 {
    font-family: Livvic;
    font-size: 33px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    -webkit-text-stroke: 1px;
    color:#fff;
}
/*.recomendation-content{height:550px;*/
/*    overflow-y: scroll;overflow-x: hidden;*/
/*}*/
.product-card {
    /*border: 0.5px solid rgb(143, 153, 163);*/
    border-radius: 10px;
    padding: 10px;
    margin: 5px 1px;
}

.product-card-img {
    max-height: 400px;
    min-height: 250px;
    width: 100%;
    /*border: 0.5px solid lightgray;*/
    border-radius: 10px;
    /*background-image: url('../../../assets/images/Rectangle\ 18761.png');*/
    /*background-size: 100% 100%;*/
    display: flex;
    justify-content: center;
    /*align-items: center;*/
}
.load-more-btn{
    display: block;
    border-radius: 12px;
    background: #70CAD1;
    font-family: Livvic;
    font-size: 13px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.15000000596046448px;
    padding: 4px 8px;
    color: #2A2A2A;
    width: 30%;
    margin: 0 auto;
}

.product-card-img img {
    /*height: auto;*/
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.product-card-info {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background: #fff;
    padding: 10px;
    margin-top: -5px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.product-card-brand {
    height: 50%;
    width: 100%;
    display: flex;
    /*justify-content: space-around;*/
    align-items: center;
    color:#fff;
}

.product-card-brand button {
    /* width: 45px; */
    /* height: 20px; */
    border-radius: 12px;
    background: #70CAD1;
    font-family: Livvic;
    font-size: 13px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.15000000596046448px;
    padding: 4px 8px;
    color: #2A2A2A;
    -webkit-text-stroke: 0.6px;
}

.product-card-brand p {
    /*font-family: Livvic;*/
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #9393A0;
    margin-top: 8%;
    width: 100%;
    /*color:#fff;*/
    -webkit-text-stroke: 0.2px;
}
.product-card-brand p span{
    float: right;
}
.product-card-title {
    height: 30%;
    width: 100%;
    /*color:#fff;*/
}

.product-card-title p {
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: 0.15px;
    /*color:#fff;*/
}

.product-card-price {
    height: 30%;
    width: 100%;
    /*color:#fff;*/
}

.product-card-price p {
    font-family: Livvic;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    /*color:#fff;*/
    /*margin-left: 4%;*/
}
.navbar-toggler:focus {
    box-shadow: none !important;
}
.recomendation-content .custom-prev-arrow {
     position: relative;
    right: -40%;
    /* top: 90%; */
    color: white;
    width: 53px;
    height: 53px;
    background-color: black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 15px;
    transform: rotate(90deg);
}
.recomendation-content .custom-next-arrow {
    position: relative;
    right: -40%;
    width: 53px;
    height: 53px;
    background-color: black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -15px;
    transform: rotate(90deg);
}

/* Media query for screens with a width less than 768px */
@media (max-width: 768px) {
    .wrapper {
        flex-direction: column;
        /* Stack elements on top of each other */
    }

    /*.black-background {*/
    /*    display: none;*/
    /*}*/
/*    .product-card {*/
/*        border: 0.5px solid rgb(143, 153, 163);*/
/*        border-radius: 10px;*/
/*        padding: 10px;*/
/*}*/
    .product-card-img{min-height: auto;max-height: max-content;}
    .boxes {
    width: 100%;
}

.box {
    width: 50%;
}

.product-card-title p {
    font-family: Livvic;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    color: #2A2A2A;
    margin-left: 3%;
}

.recomendation-heading h1 {
    font-family: Livvic;
    font-size: 23px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 15px;
    -webkit-text-stroke: 1px;
}
.container-rc {
        height: auto;
    }
    .product-selection .product-bg{
        width:100%;
    }
}


@media (max-width: 992px) {
    .btn-arrow {
        margin-left: 0;
    }
    .get-started-btn{width:90%;}
    .btn-option{width:100%;}
    .gender-selection p, .gender-selection h1{line-height: 36px;}
    .gender-selection {
        height: auto;
        width: 90%;
        margin-left:0;
        padding: 30px 0;
    }
    .product-img img{
        min-height: auto;
        border-radius: 10px;
    }
}

@media (min-width: 769px) and (max-width: 1200px){
    .back-image {width:100%;height: auto;}
    .gender-selection p, .gender-selection h1{line-height: 36px;}
    .btn-option{width:100%;}
    .style-div{padding: 50px 0;}
    .get-started-btn{width:90%;}
    .btn-arrow{margin-left: 0;}
    .gender-selection {
        height: auto;
        width: 60%;
        margin-left: 10%;
        padding: 30px 0;
    }
    .let-us-knew p {
        font-size: 25px;
        font-weight: 900;
        line-height: 37px;
        margin-left: 0;
    }

    .swipe-right {
        height: auto;
        margin-left:0;
        width: 100%;
    }
.let-us-knew{
    width:100%;
    height: auto;
}
    .product-card-img {
        /*max-height: 250px;*/
        min-height: auto;
        width: 100%;
        border: 0.5px solid lightgray;
        border-radius: 10px;
        /*background-image: url('../../../assets/images/Rectangle\ 18761.png');*/
        /*background-size: 100% 100%;*/
        display: flex;
        justify-content: center;
        /*align-items: center;*/
    }
    .style-div-child {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: auto;
        margin: 0;
        width: 100%;
    }
    .product-selection .product-bg {
        width: 90%;
        height: auto;
    }
}

@media (min-width: 1600px) {
    .style-div-child{margin:0;}
}
@media screen and (min-width: 1440px) {
    .cstm-container {
        max-width: 1440px !important;
    }
}