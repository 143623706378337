.search_filter_container{
    width: 90% !important;
}
.cstm_search{
    border-radius: 40px;
    width:100%;
    height: 50px;
    flex-shrink: 0;
    background: #F5F5F5;
    padding-left: 45px;
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 87.5% */
}
.cstm_search:focus-visible {
    outline:none !important;
}
.search_img{
    position: absolute;
    top:30%;
    left:1%;
    width: 21px;
    height: 21px;
    flex-shrink: 0;
}

.filters_btn{
    border-radius: 40px;
    color: #2A2A2A;
    font-family: Livvic;
    background: #dfdfdf;
    padding: 15px 20px !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    position: absolute;
    right: 4px;
    top: 2px;
}
.filters_dropdown{
    border-radius: 40px !important;
    color: #2A2A2A !important;
    font-family: Livvic !important;
    background: #70CAD1 !important;
    padding: 15px 20px !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 14px !important;
    position: absolute;
    right: 4px;
    top: 2px;
}
.filters_btn .filter_img{
    display: inline-flex;
    width: 15px;
    height: 14px;
    flex-shrink: 0;
    padding-right: 5px;
}



@media screen and (min-width: 1440px) {
    .search_filter_container {
        max-width: 1440px !important;
    }
}