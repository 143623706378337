.customer-review-section{

}
.customer-review-container{
    background-image: url('../../../assets/images/Subtract.svg');
    width: 90% !important;
    height: 770px;
    margin-top: 15vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}
.third-column{border-left: 1px solid #fff;}
 .coustmer-container {
     background-image: url('../../../assets/images/Subtract.svg');
     height: 840px;
     margin-top: 15vh;
     background-size: 100%;
 }

 .first-row {
     /*height: 40vh;*/
 }

 .first-column {
     /*height: 254px;*/
 }

 .coustmer-header {
     color: white;
     margin-top: 8%;
     margin-left: 10%;
     font-family: 'Livvic';
     font-size: 60px;
     font-weight: 700;
     line-height: 87px;
     text-align: left;
     -webkit-text-stroke: 3px;
 }

 .second-column {
     /*height: 260px;*/
 }

 .second-column-p {
     margin-top: 12%;
     color: #D9D9D9;
     font-family: Livvic;
     font-size: 22px;
     font-weight: 400;
     line-height: 33px;
     letter-spacing: 0em;
     text-align: left;
 }



 .second-row {
     /*height: 40vh;*/
     margin-top: 1%;
     /* border: 1px solid red; */
     width: 100%;
     margin-left: 10%;
 }

 .third-column {
     height: 100%;
     margin-top: 1px;
     display: flex;
     flex-direction: column;
 }

 .third-column-first-col {
     width: 40px;
     height: 65px;

 }

 .third-column-first-col p {
     color: #D9D9D9;
     font-family: Livvic;
     font-size: 88px;
     font-weight: 700;
     line-height: 87px;
     letter-spacing: 0em;
     margin-left: 100%;
     margin-top: 15%;

 }

 .third-column-second-col {
     width: 574px;
     height: 132px;
     margin-left: 5%;
 }

 .third-col-border{
    height: 100%;
    width: 1%;
    border-left: 1px solid #ffffff;
 }

 .third-column-second-col p {
     font-family: Livvic;
     font-size: 20px;
     font-weight: 400;
     line-height: 33px;
     letter-spacing: 0em;
     text-align: left;
     color: #D9D9D9;
     margin-left: 45px;
 }

 .third-column-third-col {
     width: 45%;
     height: 100px;
 }

 .image-div {
     display: flex;
     justify-content: space-between;
     align-items: center;
     margin-left: 20%;
 }
 

 .Antoni{
    margin-left: 15%;
 }

 .Leo{
    margin-right: 8%;
 }



 .third-column-third-col p {
     color: #D9D9D9;
     font-family: Livvic;
     font-size: 20px;
     font-weight: 400;
 }

 .arrow-btns-div {
     position: absolute;
     width: 279px;
     height: 169px;
     border-radius: 40px, 0px, 0px, 0px;
     left: 77%;
     top: 320%;
     display: flex;
     justify-content: space-evenly;
     align-items: center;
     color: #D9D9D9;

 }

.custom-prev-arrow {
    position: absolute;
    right: 10%;
    /* top: 90%; */
    color: white;
    width: 53px;
    height: 53px;
    background-color: black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -84%;
}

.custom-next-arrow {
    position: absolute;
    right: 5%;
    /* top: 90%; */
    color: white;
    width: 53px;
    height: 53px;
    background-color: black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -84%;
}



 @media (max-width: 768px) {
     .coustmer-container {

         background-image: none;
         background-color: rgb(27, 25, 25);
         height: 100vh;
         width: 100%;
         margin-top: 1vh;
         border-radius: 5%;
     }
     .customer-review-container{
         background-size: cover;
         height: 70vh;
         background: #2A2A2A;
     }

     .first-row {
         height: auto;
     }

     .first-column {
         height: 100%;
         width: 50%;
     }

     .coustmer-header {
         color: white;
         margin-top: 0%;
         margin-left: 0%;
         font-family: 'Livvic';
         font-size: 25px;
         font-weight: 700;
         line-height: 40px;
         text-align: left;
         width: 100%;
         padding: 40px 20px;
     }

     .second-column {
         height: 1%;

     }
     

     .second-column-p {
         margin-top: 0%;
         color: #D9D9D9;
         font-family: Livvic;
         font-size:16px;
         font-weight: 100;
         line-height: 15px;
         letter-spacing: 0em;
         text-align: left;
         margin-top: 0%;
         margin-left: 0%;
         width: 100%;
     }



     .second-row {
         height: auto;
         width: 100%;
     }

     .third-column {
         /*height: 70%;*/

     }

     .third-column-first-col {
         width: 30px;
         height: auto;
         padding-top: 10px;

     }

     .third-column-first-col p {
         color: #D9D9D9;
         font-family: Livvic;
         font-size: 26px;
         font-weight: 700;
         line-height: 1px;
         letter-spacing: 0em;
         padding-top: 0;

     }

     .third-column-second-col {
         width: 100%;
         height: auto;
         margin-left: 5%;
         padding-top: 0;


     }

     .third-column-second-col p {
         font-family: Livvic;
         font-size: 19px;
         font-weight: 400;
         line-height: 19px;
         letter-spacing: 0em;
         text-align: left;
         color: #D9D9D9;
         margin-left: 10px;
     }

     .third-column-third-col {
         width: 40%;
         height: auto;
         /*padding-top:120px;*/

     }

     .image-div {
         display: flex;
         justify-content: space-between;
         align-items: center;
     }



     .third-column-third-col p {
         color: #D9D9D9;
         font-family: Livvic;
         font-size: 17px;
         font-weight: 100;
     }

     .arrow-btns-div {
         display: none;

     }

     .custom-prev-arrow {
         display: none;

     }

     .custom-next-arrow {
         display: none;

     }
 }
@media (min-width: 769px) and (max-width: 1200px){
    .customer-review-container{
        background-size: cover;
        background-image: none;
        height: 70vh;
        background: #2A2A2A;
    }
    .third-column {
        height: 100%;
        margin-top: 1px;
        display: flex;
        flex-direction: column;
        width: 100% !important;
    }
    .arrow-btns-div {
        display: none;

    }

    .custom-prev-arrow {
        display: none;

    }

    .custom-next-arrow {
        display: none;

    }
    .first-row {
        height: auto;
    }

    .first-column {
        height: 100%;
        width: 50%;
    }

    .coustmer-header {
        color: white;
        margin-top: 0%;
        margin-left: 0%;
        font-family: 'Livvic';
        font-size: 25px;
        font-weight: 700;
        line-height: 40px;
        text-align: left;
        width: 100%;
        padding: 40px 20px;
    }

    .second-column {
        height: 1%;

    }
    .second-column-p {
        margin-top: 0%;
        color: #D9D9D9;
        font-family: Livvic;
        font-size:16px;
        font-weight: 100;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 20%;
        margin-left: 10%;
        width: 100%;
    }

    .second-row {
        height: auto;
        width: 100%;
    }

    .third-column {
        /*height: 70%;*/

    }

    .third-column-first-col {
        width: 30px;
        height: auto;
        padding-top: 10px;

    }

    .third-column-first-col p {
        color: #D9D9D9;
        font-family: Livvic;
        font-size: 26px;
        font-weight: 700;
        line-height: 1px;
        letter-spacing: 0em;
        padding-top: 0;

    }

    .third-column-second-col {
        width: 100%;
        height: auto;
        margin-left: 5%;
        padding-top: 0;


    }

    .third-column-second-col p {
        font-family: Livvic;
        font-size: 19px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: #D9D9D9;
        margin-left: 10px;
    }

    .third-column-third-col {
        width: 40%;
        height: auto;
        /*padding-top:120px;*/

    }

    .image-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }



    .third-column-third-col p {
        color: #D9D9D9;
        font-family: Livvic;
        font-size: 17px;
        font-weight: 100;
    }


}
@media screen and (min-width: 1440px) {
    .customer-review-container{
        max-width: 1440px !important;
    }
}