.user_review_section{padding: 50px 0 100px 0;}
.user_review_section h1{
    color: #232323;
    font-family: 'SF Pro Display Semibold' !important;
    font-size: 46px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    line-height: 56px; /* 121.739% */
    text-transform: capitalize;
    margin-bottom: 20px;
}
.client_heading{margin-bottom: 50px;}
.user_review_section p{
    color: #666;
    text-align: center;
    font-family: "SF Pro Display";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
    max-width: 700px;
    margin: 0 auto;
}
.user_review_section .user_review{
    background: url("../../../assets/images/user-review-bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 20px;
    border-radius: 30px;
    max-width: 585px !important;
    position: relative;
    margin: 0 auto;
}
.user_review p{
    color: #666;
    text-align: center;
    font-family: "SF Pro Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
    margin-bottom: 25px;
    min-height: 110px;
}
.user_review h2{
    color: #232323;
    text-align: center;
    font-family: 'SF Pro Display Semibold' !important;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 26.2px; /* 100.769% */
}
.user_review_text{
    margin-top: -30px;
}
.cstm_prev_arrow{
    position: absolute;
    background: #fff;
    color:#52BFC1;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    bottom: -10%;
    left: 47%;
    border:1px solid #52BFC1;
}

.cstm_next_arrow{
    position: absolute;
    background: #fff;
    color:#52BFC1;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    bottom: -10%;
    left: 50%;
    border:1px solid #52BFC1;
}
.cstm_next_arrow i{
    position: relative;
    top: 3px;
    left: 7px;
    display: block;
}
.cstm_prev_arrow i{
    position: relative;
    top: 3px;
    left: 6px;
    display: block;
}
.cstm_prev_arrow:hover{
    position: absolute;
    background: #52BFC1;
    color:#fff;
    width: 25px;
    height: 25px;
    border-radius: 50%;

}
.cstm_next_arrow:hover{
    position: absolute;
    background: #52BFC1;
    color:#fff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
}
/*.user_review {*/
/*    position: relative;*/
/*}*/

.user_review .user_avatar {
    margin: 0 auto;
    position: relative;
    top: -60px;
    width: 15%;
}
.user_review_text .user_rating{
    margin: 0 auto;
}
@media (max-width: 768px) {
    .user_review_section{padding: 50px 0 60px 0;}
    .user_review_section h1{
        color: #232323;
        font-family: 'SF Pro Display Semibold' !important;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 116.667% */
        text-transform: capitalize;
        text-align: center;
    }

    .user_review_section p{
        color: #666;
        text-align: center;
        font-family: "SF Pro Display";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 133.333% */
        padding: 0 20px;
    }
    .user_review_section .user_review{
        background: url("../../../assets/images/user-review-bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 20px;
        border-radius: 30px;
        max-width: 352px !important;
        position: relative;
        margin: 0 auto;
    }
    .user_review p{
        color: #666;
        text-align: center;
        font-family: "SF Pro Display";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        max-width: 320px;
        min-height: 150px;
    }
    .user_review h2{
        color: #232323;
        text-align: center;
        font-family: 'SF Pro Display Semibold' !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
    }
    .cstm_prev_arrow{
        position: absolute;
        background: #fff;
        color:#52BFC1;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        bottom: -10%;
        left: 42%;
        border:1px solid #52BFC1;
    }
    .user_review .user_avatar {
        margin: 0 auto;
        width: 30%;
        position: relative;
        top: -70px;
    }
}
@media (min-width: 769px)and (max-width: 992px){
    .user_review_section .user_review{
        background: url("../../../assets/images/user-review-bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 20px;
        border-radius: 30px;
        max-width: 335px !important;
        position: relative;
        margin: 0 auto;
    }
    .cstm_prev_arrow{
        position: absolute;
        background: #fff;
        color:#52BFC1;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        bottom: -10%;
        left: 45%;
        border:1px solid #52BFC1;
    }
}
@media (min-width: 993px)and (max-width: 1400px){
    .user_review_section .user_review{
        background: url("../../../assets/images/user-review-bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 20px;
        border-radius: 30px;
        max-width: 450px !important;
        position: relative;
        margin: 0 auto;
    }
}