/*.what_we_do_section{padding: 50px 0;}*/
.bg_section{
    background: url("../../../assets/images/what-we-do-bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 60px 0px 0 30px;
}

.what_we_do_text{
    max-width: 470px;
    position: relative;
    left: 40px;
    padding-bottom: 100px;
    top: 10px;
}
.what_we_do_text h1{
    color: #232323;
    font-family: 'SF Pro Display Semibold' !important;
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px; /* 121.739% */
    text-transform: capitalize;
}

.what_we_do_text p{
    color: #666;
    font-family: "SF Pro Display";
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 147.368% */
}
.what_we_do_text p span{
    color: #666;
    font-family: 'SF Pro Display Semibold' !important;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
}
.what_we_do_text h2{
    color: #232323;
    font-family: 'SF Pro Display Semibold' !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px; /* 141.667% */
    text-transform: capitalize;
}
.mobile_banner{display:none;}
.download_btn{
    border-radius: 30px;
    background: #52BFC1;
    color: #FFF;
    font-family: 'Helvetica bold' !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
    padding: 14px 29px;
    position: relative;
    top:10px;
}
.what_we_do_img img{
    width: 86%;
    position: relative;
    top: 25px;
    right: 10px;

}
/* Media query for screens with a width less than 768px */
@media (max-width: 768px) {
    .what_we_do_section{background: #fff;}
    .bg_section{
        padding: 60px 0px 0 0px;
        background: url("../../../assets/images/what-we-do-mob-bg.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 40%;}
    .what_we_do_text{
        max-width: 290px;
        position: relative;
        left: 0;
        padding-bottom: 0;
        top: 20px;
        text-align: center;
        margin: 0 auto;
    }
    .what_we_do_text h1{
        color: #232323;
        font-family: 'SF Pro Display Semibold' !important;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px; /* 108.333% */
        text-transform: capitalize;
        text-align: center;
    }
    .what_we_do_text p{
        color: #666;
        font-family: "SF Pro Display";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        text-align: center;
    }
    .what_we_do_text p span{
        color: #666;
        font-family: 'SF Pro Display Semibold' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        text-align: center;
    }
    .what_we_do_text h2{
        color: #232323;
        font-family: 'SF Pro Display Semibold' !important;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 100% */
        text-transform: capitalize;
        text-align: center;
    }
    .mobile_banner{display:block;}
    .desktop_banner{display: none;}
    .what_we_do_img img{
        width: 100%;
        position: relative;
        top: 40px;
        margin: auto;
        right: 0;
    }

    .download_btn{
        border-radius: 19.531px;
        background: #52BFC1;
        color: #FFF;
        font-family: 'Helvetica bold' !important;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-decoration: none;
        padding: 9.114px 18.88px;
    }
}
@media (min-width: 768px) and (max-width: 992px){
    .bg_section{
        padding: 60px 0px 0 0px;
        background: url("../../../assets/images/what-we-do-mob-new-bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;}
    .what_we_do_img img{
        width: 70%;
        margin: auto;
    }
}
@media (min-width: 993px) and (max-width: 1200px){
    .bg_section{
        padding: 60px 0px 0 30px;
        background: url("../../../assets/images/what-we-do-bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;}
    .what_we_do_img img{
        width: 70%;
        margin: auto;
    }
}

@media (min-width: 1300px){
    .what_we_do_container{max-width: 1234px !important;}
}