.footer_section{
    padding: 30px 0;
    background: #52BFC1;
}
.footer_content p{
    color: #FFF;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    margin-bottom: 0;
    display: inline-flex;
}
.footer_content ul{
    float: right;
    margin-bottom: 0;
    padding: 0;
}
.footer_content ul li {
    display: inline-flex;
    margin: 0 5px;
}
.footer_content ul li img{
    width: 38.962px;
    height: 38.962px;
}

@media (max-width: 768px) {
 .footer_content p{
    color: #FFF;
    font-family: Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
    .footer_content{
        text-align: center;
    }
    .footer_content ul{
        float: none;
        margin-bottom: 0;
        padding: 20px 0 0 0;
    }
 .footer_content ul li img{
    width: 25.96px;
    height: 25.96px;
}
}