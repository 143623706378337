/*.product_info{padding: 50px 0;}*/
.product_info_container{
    width: 90% !important;
}
.product_row {
    display: flex;
    align-items: stretch; /* To make columns match height */
}
.product_bg{
    border-radius: 20px;
    background: #F5F5F5;
    height: 100%;
    flex-shrink: 0;
}
.product_img{height:100%;}
.product_img img{margin: 0 auto;width:auto;height: 100%;}
.comment_box label{
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px; /* 120% */
    letter-spacing: -0.33px;
    margin: 30px 0;
}
.comment_box .product_comment{
    border-radius: 15px;
    background: #F5F5F5;
    width: 100%;
    padding: 10px;
    height: 127px;
    resize: none;
}
.comment_box .product_comment:focus-visible{
    outline:none !important;
    box-shadow: none !important;
}
.product_description ul{padding: 0 !important;}
.product_description ul li{
    border-radius: 30px;
    background: #70CAD1;
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 200% */
    letter-spacing: 0.15px;
    display: inline-flex;
    padding: 5px 15px;
    margin-left: 5px;
    cursor: pointer;
}
.product_title h1{
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 38px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px; /* 94.737% */
    letter-spacing: -0.33px;
}
.product_price p{
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 45px */
    letter-spacing: 0.5px;
}
.product_price .product_review{
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 145.455% */
    letter-spacing: 0.5px;
    float: right;
}
.product_price .product_review img{display: inline-flex;}
.buy_from_brand{
    border-radius: 15px;
    background: #2A2A2A;
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Livvic;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 130% */
    width: 100%;
    text-align: left;
    height: 60px;
    padding: 0 20px;
}
.buy_from_brand img {
    display: inline-flex;
    float: right;
}
.notify_product{
    border-radius: 20px;
    background: #F5F5F5;
    height: 60px;

}
.notify_product p{
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px; /* 22.5px */
    letter-spacing: 0.5px;
    padding: 10px;
}
.overview h2, .material h2{
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    letter-spacing: 0.5px;
}
.overview p, .material p{
    color: #2A2A2A;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Livvic;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 130% */
    letter-spacing: -0.078px;
}
.more_info ul{padding: 0 !important;}
.more_info ul li{
    border-radius: 15px;
    background: #F5F5F5;
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27px */
    letter-spacing: 0.5px;
    height: 60px;
    display: block;
    margin-bottom: 15px;
    padding: 15px;
}
.more_info ul li .pre_img{
    width: 20px;
    height: 20px;
    margin-right: 5px;
    position: relative;
    top: -2px;
    display: inline-block;
}
.more_info ul li span img{
   float: right;
}
.return_policy_section, .review_section, .size_guide_section{
    padding: 0 30px 100px 30px;
}
.return_policy_section h1, .review_section h1, .size_guide_section h1{
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px; /* 120% */
    letter-spacing: -0.33px;
}
.return_policy_section p{
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    letter-spacing: 0.5px;
}
.cstm_modal{
    max-width:625px !important;
    flex-shrink: 0;
}
.cstm_content{
    border-radius: 20px;
    background: #F5F5F5 !important;
}
.close_modal{
    position: absolute;
    right: -40%;
    cursor:pointer;
}
.close_modal img{background:#fff;}
.cstm_header{border-bottom: 0 !important;padding-bottom: 0 !important;}
.return_policy_section ul{
    padding: 0;
}
.return_policy_section ul li{
    color: #2A2A2A;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Livvic;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 136.842% */
    letter-spacing: -0.078px;
    margin-bottom: 15px;
}
.review_card{
    border-radius: 20px;
    background: #FFF;
    padding: 20px;
}
.review_stats{
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px; /* 120% */
    letter-spacing: -0.33px;
}
.review_stats img{display: inline;}
.review_stats span{
    font-weight: bolder;
    font-size: 32px;
}
.number_of_reviews{
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.rating_by_star ul li{
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
    letter-spacing: 0.1px;
}

.user_info h1{
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.user_info .review_date{
    color: #9A9A9A;
    font-family: Livvic;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}
.user_info .review_date .review_rating{
    border-radius: 10px;
    background: #FFF;
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    float: right;
    padding: 3px;
}
.user_info p .review_rating img{display: inline-flex;width: 9px;margin-top: -3px;}
.star_rating{top:5px;}
.review_content{
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 20px;
}

.user_info .avatar{display: inline-flex;
    float: left;
    margin-bottom: 50px;
    margin-right: 20px;}
.border_line{
    stroke-width: 0.5px;
    stroke: #ACACAC;
    opacity: 0.4;
}
.notes h1{
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    letter-spacing: 0.5px;
}
.notes ul li{
    color: #2A2A2A;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Livvic;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 136.842% */
    letter-spacing: -0.078px;
}

@media screen and (max-width:767px) {
    .product_description ul{margin-top:10px;}
}

@media screen and (max-width: 1200px) {
    .close_modal{
        position: relative;
        right: 0;
        float: right;
        cursor:pointer;
    }
}
@media screen and (min-width: 1440px) {
    .product_info_container {
        max-width: 1440px !important;
    }
}