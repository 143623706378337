.intro-div {
    width: 60%;
}
.intro-container{width:90% !important;}
.intotxt {
    font-family: Livvic;
    font-size: 40px;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: left;
    -webkit-text-stroke: 1px;
}



.intro-paragraph {
    width: 100%;
    height: 40vh;
    margin-top: 10vh;
}

.intro-p {
    font-family: Livvic;
    font-size: 25px;
    font-weight: 400;
    line-height: 46px;
    letter-spacing: 0em;
    text-align: left;
}

.intro-p-hard {
    font-family: Livvic;
    font-size: 25px;
    font-weight: 700;
    line-height: 46px;
    letter-spacing: 0em;
    text-align: left;
    -webkit-text-stroke: 1px;
}

.intro-image {
    width: 100%;
    height: 45vh;

}

.intro-image img {
    height: 100%;
    width: 100%;
    border: none;
}

@media (max-width: 768px) {
    .intro-div h2 {
        font-size: 30px;
    }

    .intro-p {
        font-size: 15px;
        font-family: Livvic;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        /*text-align: center;*/
    }

    .intotxt {
        font-family: Livvic;
        font-size: 30px;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: left;
        -webkit-text-stroke: 1px;
    }

    .intro-p-hard {
        font-family: Livvic;
        font-size: 18px;
        font-weight: 550;
        line-height: 5px;
        letter-spacing: 0em;
        text-align: center;
    }
    .intro-image {
        width: 100%;
        height: 35vh;
        margin-top: 20px;
        /* border: 1px solid red; */
    }
    
    .intro-image img {
        height: 100%;
        width: 100%;
        border: none;
    }
}
@media screen and (min-width: 1440px) {
    .intro-container {
        max-width: 1440px !important;
    }
}