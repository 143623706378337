
.contact-us-container {
    width: 90% !important;
    margin: 0 auto;
    /*height: 300px;*/
    /*margin-top: 0%;*/
    /*margin-left: 5%;*/

}
.address-info p span{
    margin-right: 10px;
}

.social-media-logos {
    width: 140px;
    height: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 8%;

}

.fb-img {
    width: 30px;
    height: 30px;

}

.twitter-img {
    margin-top: 10%;
    width: 27px;
    height: 27px;
}

.contact-first-col p {
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 5%;
    -webkit-text-stroke: 0.4px;
}

.address-parent {
    display: flex;
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 400;
}

.email-parent {
    display: flex;
    margin-top: 5%;
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 400;
}

.phone-parent {
    display: flex;
    margin-top: 5%;
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 400;

}

.address-parent-img {
    width: 16.88px;
    height: 24.01px;

}

.email-parent-img {
    width: 16.88px;
    height: 24.01px;
    padding-top: 5px;
}

.phone-parent-img {
    width: 16.88px;
    height: 24.01px;
}

.About-Atricent ul li a{
    margin-top: 4%;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    opacity: 90%;
    text-decoration: none;
    color: #282828;

}

.privacy-policy {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0px;
    text-align: left;
    color: #282828;

}

@media (max-width: 768px) {
    /*.contact-us-container {*/
    /*    margin-top: 90%;*/
    /*    height: 300px;*/
    /*}*/

    .contact-first-col {
        width: 100%;
        display: flex;
        flex-direction: column;
        /*align-items: center;*/
    }

    .social-media-logos {
        margin-top: 0%;

    }
}
@media (min-width: 769px) and (max-width: 1200px){
    .contact-us-container {
        padding-top: 50px;
    }
}
@media screen and (min-width: 1440px) {
    .contact-us-container {
        max-width: 1440px !important;
    }
}

