.like_products{
    padding: 50px 0;
}
.like_products_container{
    width: 90% !important;
}
.like_title{
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px; /* 120% */
    letter-spacing: -0.33px;
}
.prev_arrow{
    position: absolute;
    z-index: 1;
    top: 32%;
    left: -3.5%;
}
.next_arrow{
    position: absolute;
    z-index: 1;
    top: 32%;
    right: -3.5%;
}
.product_bg{
    margin-top: 35px;
    border-radius: 20px;
    background: #F5F5F5;
    height: auto;
    flex-shrink: 0;
    margin: 0 10px;
}
.product_img img{margin: 0 auto;}
.dot_img{
    position: absolute;
    bottom: 30%;
    right: 0;
}
.like_img{
    position: absolute;
    bottom: 23%;
    right: 0;
}
.product_selection .product_bg .action_close_btn{
    position: absolute;
    top: 20%;
}
.product_selection .product_bg .action_like_btn{
    position: absolute;
    top: 20%;
    right: 0;
}
.product_description{background: #000;padding: 10px 15px;}
.product_description .product_cat_info p{
    margin-bottom: 0;
}
.product_description .product_cat_info .product_tag{
    border-radius: 12px;
    background: #70CAD1;
    font-family: Livvic;
    font-size: 13px;
    font-weight: 500;
    color:#fff;
    line-height: 14px;
    letter-spacing: 0.15000000596046448px;
    padding: 4px 8px;
    color: #2A2A2A;
    -webkit-text-stroke: 0.6px;}
.product_description .product_cat_info .product_review{
    color: #FFF;
    font-family: Livvic;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 145.455% */
    letter-spacing: 0.5px;
    margin-right: 5px;
}
.product_description .product_cat_info .remaining{
    color: #FFF;
    font-family: Livvic;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 114.286% */
    letter-spacing: 0.5px;
    float: right;
}
.product_description .product_cat_info  .remaining img{
    display: inline-flex;
}
.product_description .product_cat_info  .product_title{
    color: #FFF;
    font-family: Livvic;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 120% */
    letter-spacing: 0.15px;
}
.product_description .product_cat_info  .product_price{
    color: #FFF;
    text-align: right;
    font-family: Livvic;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 96% */
    letter-spacing: 0.15px;
    float: right;
}
@media screen and (min-width: 1440px) {
    .like_products_container {
        max-width: 1440px !important;
    }
}