.search_filter_container{
    width: 90% !important;
}
.cstm_search{
    border-radius: 40px;
    width:100%;
    height: 50px;
    flex-shrink: 0;
    background: #F5F5F5;
    padding-left: 45px;
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 87.5% */
}
.cstm_search:focus-visible {
  outline:none !important;
}
.search_img{
    position: absolute;
    top:30%;
    left:1%;
    width: 21px;
    height: 21px;
    flex-shrink: 0;
}

.filters_btn{
    border-radius: 40px;
    color: #2A2A2A;
    font-family: Livvic;
    background: #dfdfdf;
    padding: 15px 20px !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    position: absolute;
    right: 4px;
    top: 2px;
}
.filters_dropdown{
    border-radius: 40px !important;
    color: #2A2A2A !important;
    font-family: Livvic !important;
    background: #70CAD1 !important;
    padding: 15px 20px !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 14px !important;
    position: absolute;
    right: 4px;
    top: 2px;
}
.filters_btn .filter_img{
    display: inline-flex;
    width: 15px;
    height: 14px;
    flex-shrink: 0;
    padding-right: 5px;
}
.cstm_filters{
    border-radius: 20px;
    border: 1px solid #DEDEDE;
    background: #FFF;
    padding: 20px;
    margin-top: 20px;
}
.cstm_filters h1{
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */
    letter-spacing: 0.5px;
}
.cstm_filters .default_filters ul, .cstm_filters .filters_by_size ul, .cstm_filters .filters_by_brands ul{
    padding-left: 0;
}
.cstm_filters .default_filters ul li{
    color: #2A2A2A;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Livvic;
    display: inline-flex;
    padding: 10px;
    flex-shrink: 0;
    height:50px;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 173.333% */
    border-radius: 15px;
    background: #F5F5F5;
    min-width: 80px;
    margin-right: 20px;
    cursor:pointer;
}
.cstm_filters .default_filters ul li:last-child{
    color: #2A2A2A;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Livvic;
    display: inline-flex;
    padding: 10px;
    flex-shrink: 0;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 173.333% */
    border-radius: 15px;
    background: #F5F5F5;
    min-width: 80px;
    margin-right: 0;
}
.cstm_filters .default_filters .grey_bubble {
    width: 15px;
    height: 15px;
    flex-shrink: 0;
    background: #D9D9D9;
    opacity: 0.5;
    margin-right: 10px;
    margin-top: 5px;
    border-radius: 50px;
}
.cstm_filters .default_filters .black_bubble {
    width: 15px;
    height: 15px;
    flex-shrink: 0;
    background: #2A2A2A;
    float: right;
    margin-right: 10px;
    margin-top: 5px;
    border-radius: 50px;
}
.cstm_filters .filters_by_size ul li{
    display: inline-flex;
    padding: 5px 10px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 7px;
    border: 0.5px solid #2A2A2A;
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.5px;
    cursor: pointer;
    margin-right: 10px;
}
.cstm_filters .filters_by_size ul li:hover{
    display: inline-flex;
    padding: 5px 10px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 7px;
    border: 0.5px solid #2A2A2A;
    background: #2A2A2A;
    color: #fff;
    font-family: Livvic;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.5px;
    cursor: pointer;
    margin-right: 10px;
}
.cstm_filters .filters_by_size ul li span{
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.5px;
    cursor: pointer;
}
.cstm_filters .filters_by_size ul li:hover span{
    color: #fff;
    font-family: Livvic;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.5px;
    cursor: pointer;
}
.cstm_filters .filters_by_brands ul li{
    display: inline-flex;
    padding: 5px 10px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 7px;
    border: 0.5px solid #2A2A2A;
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.5px;
    cursor: pointer;
    margin-right: 10px;
}
.cstm_filters .filters_by_brands ul li:hover{
    display: inline-flex;
    padding: 5px 10px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 7px;
    border: 0.5px solid #2A2A2A;
    background: #2A2A2A;
    color: #fff;
    font-family: Livvic;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.5px;
    cursor: pointer;
    margin-right: 10px;
}
.cstm_filters .filters_by_brands ul li span{
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.5px;
    cursor: pointer;
}
.cstm_filters .filters_by_brands ul li:hover span{
    color: #fff;
    font-family: Livvic;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.5px;
    cursor: pointer;
}
.cstm_filters .action_btns{
   text-align: right;
}
.clear_btn{
    border-radius: 15px;
    border: 1px solid #2A2A2A;
    width: 98px;
    height: 50px;
    flex-shrink: 0;
    color: #2A2A2A;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Livvic;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 173.333% */
    margin-right: 10px;
}
.apply_btn{
    border-radius: 15px;
    background: #70CAD1;
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Livvic;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 130% */
    width: 209px;
    height: 50px;
    flex-shrink: 0;
}
.apply_btn img{
    display: inline-flex;
    float: right;
    margin-right: 15px !important;
    width: 24px;
    height: 20px;
    flex-shrink: 0;
    /* line-height: 90px; */
    position: relative;
    top: 4px;
}
.size_active{
    display: inline-flex;
    padding: 5px 10px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 7px;
    border: 0.5px solid #2A2A2A;
    background: #2A2A2A;
    color: #fff !important;
    font-family: Livvic;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.5px;
    cursor: pointer;
    margin-right: 10px;
}
.size_active span{
    color: #fff !important;
    font-family: Livvic;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.5px;
    cursor: pointer;
}
.brand_active{
    display: inline-flex;
    padding: 5px 10px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 7px;
    border: 0.5px solid #2A2A2A;
    background: #2A2A2A;
    color: #fff !important;
    font-family: Livvic;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.5px;
    cursor: pointer;
    margin-right: 10px;
}
.brand_active span{
    color: #fff !important;
    font-family: Livvic;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.5px;
    cursor: pointer;
}
.price_range{
    width:400px !important;

}
.price_range .range-slider{
    background:#000 !important;
}
.minimum, .maximum{
    olor: #2A2A2A;
    font-family: Livvic;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.5px;
    border-radius: 7px;
    border: 0.5px solid #2A2A2A;
    display: inline-flex;
    padding: 10px 20px;
    margin-top: 10px;
}
.maximum{float: right;}


@media screen and (min-width: 1440px) {
    .search_filter_container {
        max-width: 1440px !important;
    }
}