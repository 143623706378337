.landing_page_section{
    background: url("../../assets/images/background.svg");
    background-size: cover;
    background-repeat: no-repeat;
}

/* Media query for screens with a width less than 768px */
@media (max-width: 768px) {
.landing_page_section{
    background: none;
}
}