.landing_page_navbar{padding: 35px 0;}
/*.cstm_nav_container{max-width: 1173px !important;}*/
.cstm_nav li a{
    border-radius: 30px;
    background: #52BFC1;
    color: #FFF !important;
    font-family: 'Helvetica bold' !important;
    padding: 10px 40px !important;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.logo{width:240px;}
@media (max-width: 768px) {
    .cstm_nav li {
        padding-top: 20px;
    }
}