.main-email-container{
    width: 100%;
    display: flex;
    justify-content: center;
}


.email-container {
    width: 90%;
    height: 50vh;
    border-radius: 50px;
    margin-top: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../../assets/images/Rectangle\ 18828.png');
    background-color: gray;
    background-blend-mode: multiply;
    background-size: 100% 100%; 
}

.main-email-row {
    width: 80%;
    height: 60%;
    display: flex;
    justify-content: center;
    color: wheat;

}

.main-email-row-first {
    height: 30%;
    width: 100%;
    text-align: center;
    /* border: 2px solid rgb(49, 45, 110); */

}

.main-email-row-first h1 {
    font-family: Livvic;
    font-size: 40px;
    font-weight: 700;
    /* line-height: 87px; */
    letter-spacing: 0em;
    text-align: center;
    -webkit-text-stroke: 1px;
    /* color: white; */

}

.main-email-row-second {
    height: 30%;
    width: 100%;
    /* border: 2px solid rgb(173, 33, 143); */

    
}
.cstm-email-input{
    border-radius: 70px;
    background: #FFF;
    height: 50px;
    width: 100%;
    padding-left: 20px;
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 87.5% */
}
.cstm-email-input:focus-visible {
    outline:none !important;
}
.main-email-row-second p {
    font-family: Livvic;
    font-size: 19px;
    font-weight: 400;
    /* line-height: 33px; */
    letter-spacing: 0em;
    text-align: center;
    color: white;
    /* border: 2px solid red; */


}

.main-email-row-third {
    height: 50px;
    width: 80%;
    display: flex;
    justify-content: center;
}

.email-placeholder {
    width: 40%;
    /*border-radius: 70px;*/
    /*background-color: aliceblue;*/
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*align-items: center;*/
    margin-top: 8px;

}

.email-txt {
    color: black;
    font-family: Livvic;
    font-size: 18px;
    font-weight: 500;
    margin-left: 6%;
    margin-top: 3%;
}

.email-btn {
    width: 121px;
    height: 45px;
    border-radius: 70px;
    background-color: black;
    color: white;
    margin-right: 2%;
    position: absolute;
    right: -5px;
    top: 2px;
}


@media (max-width: 768px) {
    .main-email-container{
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
    }
    .email-container {
        width: 100%;
        height: 40vh;
        border-radius: 50px;
        margin-top: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url('../../../assets/images/Rectangle\ 18916.png');
        background-color: gray;
        background-blend-mode: multiply;
        background-size: 100% 100%;
    
    }

    .main-email-row {
        width: 90%;
        height: 70%;
        display: flex;
        justify-content: center;
        color: wheat;
    
    }
    

    .main-email-row-first {
        height: 20%;
        width: 100%;
        text-align: center;
        /* border: 2px solid rgb(49, 45, 110); */
    
    }

    .main-email-row-first h1 {
        font-family: Livvic;
        font-size: 25px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
    
    
    }

    
.main-email-row-second {
    height: 35%;
    width: 100%;
    /* border: 2px solid rgb(173, 33, 143); */

    
}

.main-email-row-second p {
    font-family: Livvic;
    font-size: 17px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
    padding-top: 15px;

}

.main-email-row-third {
    height: 50px;
    width: 50%;
    padding-top: 15px;

    margin-top: 0%;
}

.email-placeholder {
    width: 70%;
    height: 30px;
    border-radius: 50px;
    background-color: aliceblue;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.email-txt {
    color: black;
    font-family: Livvic;
    font-size: 16px;
    font-weight: 100;
    margin-left: 0%;
    margin-top: 0%;
    padding-top: 10px;
    padding-left: 10px;


}

.email-btn {
    width: 80px;
    height: 30px;
    border-radius: 11px;
    background-color: black;
    color: white;
    font-size: smaller;
    top: 0;
    right: 0;

}
}
@media screen and (min-width: 1440px) {
    .email-container  {
        max-width: 1440px !important;
    }
}