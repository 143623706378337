.mobile-section{padding: 50px 0;}
.mobile-container {
    width: 90% !important;
}

.mobile-images {
    margin-left: 31%;
    height: 70%;
    width: 70%;
}


.main-mobile-div {
    margin-top: -27%;
    margin-left: 1%;
}

.mobile-text-div h1 {
    font-family: 'Livvic';
    font-size: 76px;
    font-weight: 700;
    line-height: 87px;
    -webkit-text-stroke: 3px;
}

.mobile-text-div p {
    font-family: Livvic;
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    color: #9393A0;
    width: 30vw;
}


.image-apps-div {
    width: 362px;
    height: 52px;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;

}

.apple-image-div {
    width: 173px;
    height: 52px;
    border-radius: 10px;
    border: 0.5px solid lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
}

.apple-image-div img {
    height: 80%;
    width: 80%;
}


.playstore-image-div {
    width: 173px;
    height: 52px;
    border-radius: 10px;
    border: 0.5px solid lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
}

.playstore-image-div img {
    height: 80%;
    width: 80%;
}

.qrcode {
    margin-top: 4%;
}
@media (max-width: 500px) {
    .apple-image-div {
        width: 100px;
        height: 52px;
        border-radius: 10px;
        border: 0.5px solid lightgray;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .playstore-image-div {
        width: 100px;
        height: 52px;
        border-radius: 10px;
        border: 0.5px solid lightgray;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 768px) {
    .modile-container {
        height: 100vh;
        width: 100%;
        
    }
    .mobile-images {
        margin-left: -2%;
        height: 50vh;
        width: 100%;
    }

    .mobile-text-div{
        margin-top: 30%;
    }

    .mobile-text-div h1 {
        font-family: 'Livvic';
        font-size: 40px;
        font-weight: 700;
        line-height: 87px;
    
    }

    .mobile-text-div p {
        font-family: Livvic;
        font-size: 16px;
        font-weight: 400;
        line-height: 33px;
        letter-spacing: 0em;
        text-align: center;
        color: #9393A0;
        width: 80%;
    
    }

    .image-apps-div {
        width: 90%;
        height: 52px;
        border-radius: 10px;
        display: flex;
        justify-content: space-evenly;
    
    }
    
    .apple-image-div {
        /*width: 100px;*/
        height: 52px;
        border-radius: 10px;
        border: 0.5px solid lightgray;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .apple-image-div img {
        height: 80%;
        width: 80%;
    }
    
    
    .playstore-image-div {
        /*width: 100px;*/
        height: 52px;
        border-radius: 10px;
        border: 0.5px solid lightgray;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .playstore-image-div img {
        height: 80%;
        width: 80%;
    }

    .qrcode {
        margin-top: 6%;
        margin-left: 30%;
    }
}
@media (min-width: 769px) and (max-width: 1100px){
    .mobile-text-div p{
        width:100%;
    }
}
@media screen and (min-width: 1440px) {
    .mobile-container {
        max-width: 1440px !important;
    }
}