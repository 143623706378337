.account_section{
    padding: 50px 0;
}
.cstm_account_container{width:90% !important;}
.account_listing ul li{
    border-radius: 15px;
    background: #F5F5F5;
    width: 18.5%;
    padding: 10px;
    display: inline-block;
    margin: 10px;
}
.account_listing ul li img{
   width:100%;
   border-radius: 13px;
}
.account_listing ul li p{
    color: #2A2A2A;
    font-family: Livvic;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 120% */
    letter-spacing: 0.15px;
    margin-bottom: 0;
    margin-left: 10px;
}
.account_listing ul li span{
    color: #9393A0;
    font-family: Livvic;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 145.455% */
    letter-spacing: 0.5px;
    margin-left: 10px;
}
@media screen and (max-width: 768px) {
    .account_listing ul li{
        border-radius: 15px;
        background: #F5F5F5;
        width: 42%;
        padding: 10px;
        display: inline-block;
        margin: 10px;
    }
}
@media screen and (min-width: 769px) and (max-width: 992px) {
    .account_listing ul li{
        border-radius: 15px;
        background: #F5F5F5;
        width: 30%;
        padding: 10px;
        display: inline-block;
        margin: 10px;
    }
}
@media screen and (min-width: 993px) and (max-width: 1140px) {
    .account_listing ul li{
        border-radius: 15px;
        background: #F5F5F5;
        width: 22%;
        padding: 10px;
        display: inline-block;
        margin: 10px;
    }
}
@media screen and (min-width: 1440px) {
    .cstm_account_container {
        max-width: 1440px !important;
    }
}