.our_why_text{max-width: 425px;margin-top: 60px;margin-left: 20px;}
.our_why_text h1{
    color: #232323;
    text-align: left;
    font-family: 'SF Pro Display Semibold' !important;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}
.our_why_text p{
    color: #666;
    font-family: "SF Pro Display";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 147.368% */
}
.our_why_text p span{
    color: #666;
    font-family: 'SF Pro Display Semibold' !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
}
.our_why_text a{
    color: #FFF;
    font-family: 'Helvetica bold' !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 30px;
    background: #52BFC1;
    padding: 14px 29px;
    text-decoration: none;
    max-width: 190px;
    display: block;
}
/*.our_why_img img{width: 100%;}*/
.who_we_are_section {margin-top:200px;padding: 50px 0;}
.who_we_are_text {
    position: absolute;
    max-width: 469px;
}
.who_we_are_text h1{
    color: #232323;
    text-align: left;
    font-family: 'SF Pro Display Semibold' !important;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}
.desktop_hide{display: none;}
.who_we_are_text p{
    color: #666;
    font-family: "SF Pro Display";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 147.368% */
}
.who_we_are_text p span{
    color: #666;
    font-family: 'SF Pro Display Semibold' !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
}
.who_we_are_text a{
    color: #FFF;
    font-family: 'Helvetica bold' !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 30px;
    background: #52BFC1;
    padding: 14px 29px;
    text-decoration: none;
    max-width: 190px;
    display: block;
}
.who_we_are_img{
    position: relative;
    right: -27%;
    top: -19%;
}
.who_we_are_img img{width:80%;}


/* Media query for screens with a width less than 768px */
@media (max-width: 768px) {
    .our_why_section{background: #fff;}
    .who_we_are_section{background: #fff;}
    .who_we_are_text{
        /*padding:0 30px;*/
        margin-top: 30px;position: relative;right: 0;text-align: center;}
    .desktop_hide{display: block;}
    .mob_hide{display: none;}
    .our_why_text{
        /*padding:0 30px;*/
        margin-top: 0;text-align: center;margin-left: 0;}
    .our_why_text h1, .who_we_are_text h1{
        color: #232323;
        font-family: 'SF Pro Display Semibold' !important;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 100% */
        text-transform: capitalize;
        text-align: center;
    }
    .our_why_text p, .who_we_are_text p{
        color: #666;
        text-align: center;
        font-family: "SF Pro Display";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        padding: 0 20px;
        line-height: 20px; /* 133.333% */
    }

    .our_why_text p span, .who_we_are_text p span{
        color: #666;
        font-family: 'SF Pro Display Semibold' !important;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
    }
    .our_why_text a, .who_we_are_text a{
        color: #FFF;
        font-family: 'Helvetica bold' !important;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border-radius: 22.267px;
        background: #52BFC1;
        padding: 10.391px 21.525px;
        text-decoration: none;
        ax-width: max-content;
        display: inline-block;
    }
    .our_why_img img{
       /*margin: 0 auto;*/
        width: 100%;
    }
    .who_we_are_img img{width:100%;padding-top: 50px;}
    .who_we_are_img{
        position: relative;
        right: 0;
        top: 0;
    }
    .who_we_are_section {margin-top:0;padding: 0;}

}
@media (min-width: 1100px) {
    .cstm_container{max-width: 1071px !important;}
}